import { environment } from 'src/environments/environment';

let currentUrl = 'http://localhost:8080/api';
const baseUrls = {
  local: 'https://zs-clinical-enrollment-forecasting.dev.zsservices.com/api/CTEMP',
  dev: 'https://zs-clinical-enrollment-forecasting.dev.zsservices.com/api/CTEMP',
  qa: ' https://zs-clinical-enrollment-forecasting.qa.zsservices.com/api/CTEMP',
  stg: 'https://api.zs-clinical-enrollment-forecasting.stg.zsservices.com/CTEMP',
  uat: 'https://api.zs-clinical-enrollment-forecasting.uat.zsservices.com/CTEMP',
  production: 'https://api.zs-clinical-enrollment-forecasting.dev.zsservices.com/CTEMP',
  eks_poc_integration: 'https://zs-clinical-enrollment-forecasting.dev.zsservices.com/api/CTEMP',
};

const currentUrlSet = (env: string): string => {
  return baseUrls[env];
};

const helpers = {

  setCurrentUrl: (): string => {
    currentUrl = currentUrlSet(environment.env);
    return currentUrl;
  },
  getCurrentUrl: (dev: boolean): string => (dev ? baseUrls.dev : currentUrl),
};

export const consolidatedBaseUrl = Object.assign(baseUrls, helpers);
